<template>
  <v-row justify="center">
    <v-dialog
      content-class="dialog"
      max-width="788"
      min-height="500"
      :value="show"
      @click:outside="$emit('closed')"
    >
      <v-card>
        <h3 class="title">Новый канал</h3>
        <v-form ref="form" style="width: 100%" v-model="valid">
          <p class="subtitle">Название канала</p>
          <v-text-field
            class="input"
            color="#006FC0"
            outlined
            placeholder="Название канала"
            name="name"
            type="text"
            :rules="[rules.required]"
            v-model="title"
          ></v-text-field>
          <p class="subtitle">Ref</p>
          <v-text-field
            class="input"
            color="#006FC0"
            outlined
            placeholder="Ref"
            name="ref"
            type="text"
            v-model="ref"
            hint="Изменить будет нельзя. Если не указать, то будет сгенерирован автоматически"
            :persistent-hint="!this.editedId"
            :readonly="!!this.editedId"
            :disabled="!!this.editedId"
          ></v-text-field>
          <p class="subtitle">
            Ref_source
          </p>
          <v-text-field
            class="input"
            color="#006FC0"
            outlined
            placeholder="Ref_source"
            name="ref_source"
            type="text"
            v-model="ref_source"
            hint="Изменить будет нельзя. Если не указать, то будет сгенерирован автоматически"
            :persistent-hint="!this.editedId"
            :readonly="!!this.editedId"
            :disabled="!!this.editedId"
          ></v-text-field>
          <div class="url">
            <div class="field">
              <p class="subtitle" v-show="this.editedId">
                URL
              </p>
              <v-text-field
                class="input"
                color="#006FC0"
                outlined
                name="url"
                type="text"
                v-model="url"
                v-show="this.editedId"
                :readonly="!!this.editedId"
                :disabled="!!this.editedId"
              ></v-text-field>
            </div>

            <v-btn
              class="download-btn"
              v-show="this.editedId"
              @click="downloadCode()"
            >
              Скачать qr-code
            </v-btn>
          </div>
        </v-form>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="submin-btn"
            color="#006FC0"
            :disabled="!valid"
            @click="submit()"
          >
            Отправить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
export default {
  props: ["show", "editedId"],
  data() {
    return {
      title: "",
      ref: "",
      ref_source: "",
      url: "",
      rules: {
        required: (value) => !!value || "Обязательное поле",
      },
      valid: false,
    };
  },
  methods: {
    submit() {
      if (this.valid) {
        if (this.editedId == null) {
          this.$emit("addNew", {
            title: this.title,
            ref: this.ref,
            ref_source: this.ref_source,
          });
        } else {
          this.$emit("edit", {
            id: this.editedId,
            title: this.title,
          });
        }

        this.$emit("closed");
      }
    },
    downloadCode() {
      return new Promise((resolve, reject) => {
        axios
          .get(`/channels/${this.editedId}/qrcode`, {
            responseType: "arraybuffer",
          })
          .then((resp) => {
            const url = window.URL.createObjectURL(new Blob([resp.data]));

            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${this.ref}_qrcode.png`);
            link.click();

            window.URL.revokeObjectURL(url);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    getChannel() {
      return new Promise((resolve, reject) => {
        axios
          .get(`/channels/${this.editedId}`)
          .then((resp) => {
            let data = resp.data;
            this.title = data.title;
            this.ref = data.ref;
            this.url = data.url;
            this.ref_source = data.ref_source;
            this.$refs.form.resetValidation();
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
  },
  watch: {
    show: function() {
      if (this.show && this.editedId !== null) {
        this.getChannel();
      } else {
        this.title = "";
        setTimeout(() => {
          this.$refs.form.reset();
        }, 100);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .v-card {
    width: 788px !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    padding: 34px 38px 26px 39px;
    &__actions {
      align-self: flex-end;
      padding: 0 !important;
      margin-top: 17px;
    }
    .title {
      align-self: center;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      text-transform: uppercase;
      color: #006fc0;
      margin-bottom: 62px;
    }
    .subtitle {
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      color: #006fc0;
      opacity: 0.8;
      padding: 0;
      margin: 0;
    }
    .info {
      &-text {
        span {
          font-weight: bold;
        }
      }
    }
  }
  .input {
    width: 100%;
  }
  .url {
    display: flex;
    align-items: center;

    .field {
      width: 100%;
    }

    .download-btn {
      margin-left: 30px;
      margin-bottom: 11px;
    }
  }
  .submin-btn {
    height: 42px;
    color: white !important;
    &.v-btn--disabled {
      background: #7b7b7b !important;
    }
  }
}
</style>
