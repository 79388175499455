<template>
  <div class="channels">
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      top
      color="#006FC0"
    ></v-progress-linear>
    <div class="content">
      <!-- кнопка вызова модалки добавления канала -->
      <v-btn
        color="white"
        class="btn"
        @click="
          editedId = null;
          channelsModalShow = true;
        "
      >
        <v-icon class="btn__icon">mdi-plus</v-icon>
        <span class="btn__text">Добавить</span>
      </v-btn>
      <!-- таблица с рассылками -->
      <div class="table-wrapper" v-show="channels.length > 0">
        <v-simple-table class="table" fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  class="sticky-header text-left"
                  :class="scrollPosition >= 235 ? 'shadow' : null"
                >
                  №
                </th>
                <th
                  class="sticky-header text-left"
                  :class="scrollPosition >= 235 ? 'shadow' : null"
                >
                  Название
                </th>
                <th
                  class="sticky-header text-left"
                  :class="scrollPosition >= 235 ? 'shadow' : null"
                >
                  Cсылка
                </th>
                <th
                  class="sticky-header text-left"
                  :class="scrollPosition >= 235 ? 'shadow' : null"
                >
                  Привлечено пользователей
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in channels.slice(0, sliceValue)"
                :key="item.id"
              >
                <td>{{ index + 1 }}</td>
                <td>{{ item.title }}</td>
                <td>
                  {{ item.url }}
                </td>
                <td
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  "
                >
                  <div class="text">
                    {{ item.users_count }}
                  </div>
                  <div class="actions">
                    <v-btn
                      class="btn"
                      text
                      @click="
                        editedId = item.id;
                        channelsModalShow = true;
                      "
                      ><v-icon size="24" color="rgba(0, 0, 0, 0.6)"
                        >mdi-pencil</v-icon
                      ></v-btn
                    >
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>
    <!-- если нет созданных каналов-->
    <div class="noCards" v-show="channels.length === 0 && !channelsModalShow">
      <div class="text">
        Для добавления канала нажмите кнопку “Добавить”
      </div>
    </div>
    <new-channels-modal
      :show="channelsModalShow"
      :editedId="editedId"
      @closed="channelsModalShow = false"
      @addNew="addChannel($event)"
      @edit="editChannel($event)"
    />
  </div>
</template>

<script>
import axios from "axios";
import newChannelsModal from "@/components/Channels/newChannelsModal";
export default {
  components: {
    newChannelsModal,
  },
  data() {
    return {
      channelsModalShow: false,
      editedId: null,

      channels: [],

      fileToUpload: null,
      loading: false,
      sliceValue: 50,
      scrollPosition: null,
    };
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
      /* Если высота окна + высота прокрутки больше или равны высоте всего документа, то  */
      if (
        window.innerHeight + window.scrollY >= document.body.clientHeight &&
        this.channels.length > this.sliceValue
      ) {
        this.sliceValue += 50;
      }
    },
    getChannels() {
      return new Promise((resolve, reject) => {
        axios
          .get("/channels/")
          .then((resp) => {
            this.channels = resp.data;
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    addChannel(data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/channels/", data)
          .then((resp) => {
            this.channelsModalShow = false;
            this.getChannels();
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    editChannel(data) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/channels/${data.id}`, { title: data.title })
          .then((resp) => {
            this.channelsModalShow = false;
            this.getChannels();
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
  },

  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  destroy() {
    window.removeEventListener("scroll", this.updateScroll);
  },
  created() {
    this.getChannels();
  },
};
</script>

<style lang="scss" scoped>
.channels {
  position: relative;
  height: 100%;
  .content {
    padding: 87px 243px 118px 243px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.v-data-table .sticky-header {
  position: sticky;
  top: 70px;
  background: white;
  z-index: 2;
  &.shadow {
    box-shadow: 3px 5px 4px 0px rgba(0, 0, 0, 0.2) !important;
  }
}
.table {
  &-wrapper {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    background: white;
    border-radius: 5px;
    margin-top: 36px;
  }
  thead {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  th {
    padding: 13px 23px !important;
    text-transform: uppercase;
  }
  th:nth-child(1) {
    border-top-left-radius: 5px;
  }
  th:nth-child(5) {
    border-top-right-radius: 5px;
  }
  td {
    padding: 0 23px !important;
  }
  .actions {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    .btn {
      min-width: 24px !important;
      width: 24px !important;
      height: 24px !important;
    }
  }
}
.noCards {
  z-index: 10;
  position: absolute;
  width: 100%;
  background: rgba(108, 108, 108, 0.15);
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .text {
    max-width: 408px;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #333333;
    margin-bottom: 32px;
  }
}
.btn {
  position: relative;
  z-index: 11;
  padding: 0 !important;
  align-self: flex-end;
  width: 167px;
  height: 47px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  &__icon {
    color: #006fc0 !important;
    font-size: 37px !important;
    margin-right: 11px !important;
  }
  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #006fc0 !important;
    margin-right: 8px;
  }
}
</style>
